import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"

import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav"
import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import LeftMenu from "./leftMenu"
import styled from "@emotion/styled"

const DirectorStyle = styled.div`
  margin-bottom: 40px;
`

const BoardOfDirectors = () => (
  <Layout>
    <SEO title="National Student Essay Competition" />

    <div className="subpageContainer">
      <LeftMenu active="board-of-directors" />
      <div className="body">
        <h1 style={{ marginTop: "55px" }}>Board of Directors</h1>

        <h4>National Student Essay Competition</h4>
        <h3>Today&rsquo;s Students, Tomorrow&rsquo;s America Project</h3>

        <div className="board-of-directors">
          <DirectorStyle>
            <p>
              <strong>Adam Stone</strong>, Chair, is publisher of Examiner
              Media, an award-winning local news outlet serving Westchester and
              Putnam counties in the state of New York. He has worked in the
              local news industry for the past two decades. He founded Examiner
              Media in 2007 and prior to becoming a publisher/business owner, he
              worked as a reporter. As a reporter, Stone covered local
              government and handled general interest beats for The Journal News
              weeklies division, Yorktown&rsquo;s North County News, among
              others.
            </p>
            <p>
              A Long Island native, Adam graduated with a journalism degree from
              Hofstra University in 2001 after training with Pulitzer
              Prize-winning Newsday legend Bob Greene. Stone won awards for news
              reporting, local government coverage and sports feature writing
              when working as a community journalist. Examiner Media has also
              been decorated with awards by the New York Press Association for
              its editorials, sports coverage, news writing and more. As the
              founder, sole owner and publisher, Stone oversees the
              organization&rsquo;s editorial and commercial departments. His
              duties include hiring and managing staff, setting priorities, and
              running daily operations. He helps guide the general editorial
              focus of the outlet but defers to the editorial team on day-to-day
              decisions while assisting with some journalistic functions. An
              occasional essayist for Examiner Media publications, Stone&rsquo;s
              main responsibility is running the business side of the company.{" "}
            </p>
          </DirectorStyle>

          <DirectorStyle>
            <p>
              <strong>Debbie Kotter</strong> Barkley is a dedicated
              philanthropist and long-time community advocate with professional
              expertise and management in the consumer marketplace. As Chair of
              the Educational Resource Development Council (ERDC), her
              leadership is focused on learning about and promoting the latest
              advances and techniques in the field of health as well as
              providing support of educational scholarships, medical technology,
              and health care programs at the University of Utah. She is an
              inaugural and continuing member of the Moran Eye Center Vision
              Board. In addition to tutoring 3rd grade students in United
              Way&rsquo;s Math Pilot Program for underserved children, Debbie
              has more than 30 years experience in the fashion industry.
            </p>
          </DirectorStyle>

          <DirectorStyle>
            <p>
              <strong>Stephanie Mackay</strong> has spent the last two decades
              in the nonprofit sector working as a social entrepreneur and
              nonprofit leader where she brings a strong voice to the table to
              promote innovative solutions for the most vulnerable citizens in
              our communities. She is currently serving a three-year appointment
              to the Federal Reserve Board of Governors Community Advisory
              Council (CAC)
              (https://www.federalreserve.gov/aboutthefed/cac.htm), a 15-person
              volunteer council that meets twice yearly with the Federal Reserve
              Chairman and Board of Governors in Washington, D.C. This is one of
              three advisory councils that report directly to the Federal
              Reserve Chair and Board of Governors. The CAC has been
              instrumental in providing real-time community-level insights as
              the Federal Reserve has navigated the economic fallout of the
              pandemic. She also sits on the advisory board of the
              Philadelphia-based Global Interdependence Center
              (www.interdependence.org); the board of Utah-based Switchpoint
              (www.switchpointcrc.org); Salt Lake County's Community and Support
              Service Advisory Council (CSSAC) (Boards and Commissions - Get
              Involved | SLCo); and Salt Lake City's CARES Community Advisory
              Panel. She is the former Chief Innovation Officer at Columbus
              Community Center, a nonprofit agency that serves individuals with
              some of the most severe disabilities (www.columbusserves.org). She
              has held positions that have given her unique insight into the
              day-to-day triumphs and struggles of families of all
              socio-economic levels who face many overwhelming obstacles to
              navigate family, employment, healthcare, education, and housing
              issues. In 2015, Stephanie was recognized by Utah Business
              Magazine as one of “30 Women to Watch”
              (https://youtu.be/8MtM229Dl4o). In 2018 she was named a finalist
              for the Utah Region Ernst and Young Entrepreneur of the Year
              (https://youtu.be/n-b7m6DMck0). She received her B.A. from
              Westminster College, her M.A. from Utah State University, and a
              Conflict Resolution Certificate from the University of Utah.
            </p>
          </DirectorStyle>

          <DirectorStyle>
            <p>
              <strong>Paul T. Moxley</strong>, of counsel to the law firm of
              Cohne Kinghorn, has practiced law since 1973 specializing in
              complex matters including securities litigation, white-collar
              crime, product liability matters, intellectual property, and
              commercial litigation.
            </p>
            <p>
              Rated “AV Preeminent” by Martindale Hubbell, Paul is admitted into
              practice before the United States Supreme Court, DC Circuit Court
              of Appeals, the Third, Ninth, Tenth, and Eleventh Circuit Courts
              of Appeals, United States Court of Claims, and all State and
              Federal Courts in Utah.
            </p>
            <p>
              As chair of the American Bar Association&rsquo;s Standing
              Committee of the Federal Judiciary that vetted more than 400
              judges for the Bench and multiple Supreme Court judges during his
              three-year committee tenure, Paul testified before the United
              States Senate Judiciary Committee concerning the professional
              qualifications of Judge Brett M. Kavanaugh to be Associate Justice
              of the United States Supreme Court. Other ABA leadership positions
              include Paul&rsquo;s state chairmanship of the American Bar
              Foundation, membership on the ABA Board of Governors, and
              President of the National Conference of Bar Presidents.
            </p>
            <p>
              Paul has served as president of the Utah State Bar which has also
              paid tribute to him as Distinguished Lawyer of the Year and
              established the Paul T. Moxley Award for Mentoring in his honor.
              Paul interned in college for Senator Frank E. Moss in Washington,
              D.C.; in law school, for District Attorney Arlen Specter of
              Philadelphia; and clerked for David T. Lewis, Chief Judge of the
              Tenth Circuit for the United States Circuit Court.{" "}
            </p>
            <p>
              An as elected member, Paul served on the Alta, Utah Town Council,
              for 15 years.
            </p>
          </DirectorStyle>

          <DirectorStyle>
            <p>
              <strong>Terry Orme</strong> started at The Salt Lake Tribune as a
              copy boy, and retired 39 years later as editor and publisher. The
              newspaper won a 2017 Pulitzer Prize for local reporting under his
              leadership. In 2022, he served as a Pulitzer Prize juror.
            </p>
          </DirectorStyle>

          <DirectorStyle>
            <p>
              <strong>Dylan Smith</strong> is the Editor and Publisher of the
              Tucson Sentinel, a pioneering nonprofit local independent online
              news organization that reports authentically local journalism for
              Southern Arizona's Borderlands. Smith was the founding Chairman of
              the Board of Directors of the Local Independent Online News (LION)
              Publishers, organizing a group of publishers of hundreds of local
              news websites across the country. He's the president of the
              Arizona Press Club, and a member of the national Professional
              Standards and Ethics Committee of the Society of Professional
              Journalists. He is a longtime member of Investigative Reporters
              and Editors, the Society of Professional Journalists and American
              Copy Editors Society, as well as the Institute for Nonprofit News,
              and is a member of the Rebuild Local News Coalition advocating for
              policies and legislation that support the local news entrepreneurs
              who are fixing the new business across the country.
            </p>
            <p>
              Smith has won numerous state and national awards and accolades for
              breaking news and investigative journalism, including the
              Sledgehammer Award from the Arizona Press Club, and has been a
              Brechner Reporting Fellow at the Brechner Center for Freedom of
              Information at the University of Florida, supporting his work
              pursuing in-depth journalism about government secrecy, and twice a
              Guggenheim Justice Reporting Fellow at the Center on Media, Crime
              and Justice, John Jay College of Criminal Justice, City University
              of New York, supporting his reporting on police cover-ups and
              justice reform efforts.
            </p>
          </DirectorStyle>
        </div>
      </div>
    </div>
  </Layout>
)

export default BoardOfDirectors
